var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{staticClass:"invoices-export-table-columns-combobox",attrs:{"dense":"","multiple":"","outlined":"","hide-details":"","label":"Выберите столбцы","items":_vm.invoicesColumns,"menu-props":{
    maxHeight: '250px',
    contentClass: 'invoices-export-table-columns-combobox-menu'
  },"value":_vm.selectedColumns},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true},(_vm.selectedColumns.length)?{key:"prepend-inner",fn:function(){return [_vm._v(" "+_vm._s(_vm.selectedColumnText)+" ")]},proxy:true}:null,{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"px-3",on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectColumn(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-2 my-0"},[_c('v-checkbox',{attrs:{"value":_vm.selectedColumns.includes(item)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item === 'all' ? 'Все' : item)+" ")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }