<template>
  <v-dialog
    :value="dialogs.exportInvoices"
    width="600"
    class="px-4"
    :overlay-opacity="0.7"
    :persistent="loading.exportInvoices"
    scrollable
    :transition="false"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="60"
        dark
        color="primary"
        class="pl-2 pr-0"
      >
        <v-toolbar-title>
          Экспорт таблицы
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pa-6">
        <div class="invoice-export-table-radio-group">
          <span> Формат </span>
          <v-radio-group
            v-model="invoiceExportData.fileType"
            class="d-flex align-center mt-0 pt-0"
            hide-details
          >
            <v-radio
              v-for="fileType in ['.xlsx', '.csv']"
              :key="fileType"
              :label="fileType"
              :value="fileType"
            />
          </v-radio-group>
        </div>
        <div class="d-flex flex-column invoice-export-table-select-group">
          <span> Данные </span>

          <invoices-export-filter-range-dialog
            label="Выберите период"
            :range-items-list="historyRangeList"
            :selected-range="selectedRange"
            @handleSelected="handleSelectedRange"
          />

          <invoices-export-columns-select
            :selected-columns="invoiceExportData.columns"
            :handle-select-column="handleSelectColumn"
            :invoices-columns="invoicesColumns"
          />
        </div>
      </v-card-text>

      <v-card-actions class="px-6 pt-3 pb-5 invoice-export-table-dialog-actions">
        <v-spacer />

        <v-btn
          text
          color="grey"
          class="px-4 py-3"
          @click="close"
        >
          <span>закрыть</span>
        </v-btn>
        <v-btn
          text
          color="blue"
          class="px-4 py-3 invoice-export-table-dialog-actions-export-button"
          :disabled="exportIsDisabled"
          :loading="loading.exportInvoices"
          @click="handleExportInvoices"
        >
          <span>Экспорт</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import InvoicesExportFilterRangeDialog from '@/components/invoices/InvoicesExportFilterRangeDialog.vue';
import InvoicesExportColumnsSelect from '@/components/invoices/InvoicesExportColumnsSelect.vue';
import invoicesColumns from '@/constants/invoices/invoicesColumns';

export default {
  name: 'InvoicesExportDialog',
  components: { InvoicesExportColumnsSelect, InvoicesExportFilterRangeDialog },
  data() {
    return {
      selectedRange: null,
      invoiceExportData: {
        fileType: '.xlsx',
        columns: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'invoices/loading',
      dialogs: 'invoices/dialogs',
    }),

    historyRangeList() {
      const today = new Date();
      const todayISOString = today.toISOString().substring(0, 10);

      const firstDayOfLastMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const lastDayOfLastMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      const firstDayOfThisMonth = moment().subtract(0, 'months').startOf('month').format('YYYY-MM-DD');

      const firstDayOfThisYear = moment().subtract(0, 'years').startOf('year').format('YYYY-MM-DD');
      const firstDayOfLastYear = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
      const lastDayOfLastYear = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');

      return [
        {
          value: 'forToday',
          name: 'Сегодня',
          startDate: todayISOString,
          endDate: todayISOString,
        },
        {
          value: 'forThisMonth',
          name: 'За текущий месяц',
          startDate: firstDayOfThisMonth,
          endDate: todayISOString,
        },
        {
          value: 'lastMonth',
          name: 'За прошлый месяц',
          startDate: firstDayOfLastMonth,
          endDate: lastDayOfLastMonth,
        },
        {
          value: 'forThisYear',
          name: 'За текущий год',
          startDate: firstDayOfThisYear,
          endDate: todayISOString,
        },
        {
          value: 'forLastYear',
          name: 'За прошлый год',
          startDate: firstDayOfLastYear,
          endDate: lastDayOfLastYear,
        },
        {
          value: 'specialDate',
          name: 'Точная дата',
          startDate: this.selectedRange?.startDate,
          endDate: this.selectedRange?.startDate,
        },
        {
          value: 'range',
          name: 'Диапазон',
          startDate: '',
          endDate: '',
        },
      ];
    },

    invoicesColumns() {
      return invoicesColumns;
    },

    exportIsDisabled() {
      const { startDate, endDate } = this.selectedRange || {};
      return Boolean(!startDate || !endDate || !this.invoiceExportData.columns.length);
    },
  },
  methods: {
    close() {
      if (!this.loading.exportInvoices) {
        this.$store.dispatch('invoices/closeDialog', 'exportInvoices');
      }
    },

    handleSelectedRange(data) {
      this.selectedRange = data;
    },

    handleSelectColumn(column) {
      if (column === 'all' && this.invoicesColumns.length === this.invoiceExportData.columns.length) {
        this.invoiceExportData.columns = [];
      } else if (column === 'all') {
        this.invoiceExportData.columns = this.invoicesColumns;
      } else {
        const foundedColumn = this.invoiceExportData.columns.find((el) => el === column);

        if (foundedColumn) {
          this.invoiceExportData.columns = this.invoiceExportData.columns.filter((el) => el !== column).filter((el) => el !== 'all');
        } else {
          this.invoiceExportData.columns = [
            ...this.invoiceExportData.columns,
            column,
          ];
        }
      }
    },

    handleExportInvoices() {
      const updatedExportList = {
        fileType: this.invoiceExportData.fileType.replace('.', ''),
        columns: this.invoiceExportData.columns.filter((el) => el !== 'all'),
        startDate: this.selectedRange.startDate,
        endDate: this.selectedRange.endDate,
      };

      this.$store.dispatch('invoices/loadExportInvoices', updatedExportList);
    },
  },
};
</script>

<style lang="scss">
.invoice-export-table-radio-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.invoice-export-table-radio-group .v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row;
  display: flex;
  align-items: baseline;
  gap: 24px;
}

.invoice-export-table-radio-group .v-input--radio-group--column .v-input__slot {
  margin-bottom: 0;
}

.invoice-export-table-select-group {
  gap: 20px;
}

.invoice-export-table-dialog-actions {
  border-top: 1px solid #424242;
}

.invoice-export-table-dialog-actions-export-button .v-btn__loader .v-progress-circular svg {
  width: 14px;
  height: 14px;
}
</style>
