<template>
  <v-menu
    v-model="calendarMenuIsOpen"
    :close-on-content-click="false"
    offset-y
  >
    <template #activator="{ on }">
      <div v-on="on">
        <v-text-field
          :value="formattedDate"
          :label="label"
          readonly
          outlined
          dense
          hide-details
          class="invoices-date-calendar-field-input"
        >
          <template #append>
            <v-icon
              class="mt-1"
              size="16"
              :color="calendarMenuIsOpen ? 'primary' : ''"
              v-on="on"
            >
              mdi-calendar-range
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      :value="value"
      no-title
      color="blue darken-2"
      class="invoices-date-calendar-field-date-picker"
      :month-format="month => month.toUpperCase()"
      @input="changeDate"
    />
  </v-menu>
</template>

<script>
import moment from 'moment/moment';

export default {
  name: 'InvoicesDateCalendarField',
  props: ['value', 'label'],
  data: () => ({
    calendarMenuIsOpen: false,
  }),
  computed: {
    formattedDate() {
      return this.value ? moment(this.value).format('DD.MM.YYYY') : '';
    },
  },
  methods: {
    changeDate(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style>
  .invoices-date-calendar-field-date-picker,
  .invoices-date-calendar-field-input label,
  .invoices-date-calendar-field-input {
    font-family: 'Roboto', serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 115%;
    flex: 1;
  }

  .invoices-date-calendar-field-date-picker button {
    text-transform: capitalize;
  }
</style>
