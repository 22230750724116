<template>
  <div class="invoices-export-filter-range-row-container">
    <v-combobox
      :label="label"
      :items="rangeItemsList"
      :value="selectedRange"
      outlined
      hide-details
      item-text="name"
      height="40"
      dense
      :menu-props=" {
        maxHeight: '300',
        rounded: 'lg',
        contentClass: 'invoices-export-filter-range-dialog-combobox-menu',
        closeOnClick: true,
        closeOnContentClick: true
      } "
      class="invoices-export-filter-range-dialog-combobox"
    >
      <template #selection="{ item }">
        <span>{{ item.name }}</span>
      </template>

      <template #item="{ item }">
        <v-list-item
          :class="{
            'invoices-export-filter-range-dialog-combobox-selected-item': isSelected(item)
          }"
          @click="changeSelectedRange(item)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>

    <!--  SPECIFIC DATE  -->
    <invoices-date-calendar-field
      v-if="isRange('specialDate')"
      :value="selectedRange.startDate"
      label="Выберите дату"
      @change="handleCurrentDate"
    />

    <!--  RANGE  -->
    <div
      v-if="isRange('range')"
      class="d-flex align-center invoices-export-filter-range-box"
    >
      <invoices-date-range-calendar-field
        :value="selectedRange.startDate"
        label="Начала периода"
        @change="handleRangeDate('startDate', $event)"
      />

      <div class="mx-2"> - </div>

      <invoices-date-range-calendar-field
        :value="selectedRange.endDate"
        label="Конец периода"
        @change="handleRangeDate('endDate', $event)"
      />
    </div>
  </div>
</template>

<script>
import InvoicesDateCalendarField from '@/components/invoices/InvoicesDateCalendarField.vue';
import InvoicesDateRangeCalendarField from '@/components/invoices/InvoicesDateRangeCalendarField.vue';

export default {
  name: 'InvoicesExportFilterRangeDialog',
  components: {
    InvoicesDateRangeCalendarField,
    InvoicesDateCalendarField,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    rangeItemsList: {
      type: Array,
      required: true,
    },
    selectedRange: {
      required: true,
      default: null,
    },
  },
  methods: {
    isSelected(item) {
      return this.selectedRange?.name === item.name;
    },

    isRange(value) {
      return this.selectedRange?.value === value;
    },

    handleCurrentDate(value) {
      const rangeData = {
        ...this.selectedRange,
        startDate: value,
        endDate: value,
      };

      this.changeSelectedRange(rangeData);
    },

    handleRangeDate(key, value) {
      const rangeData = {
        ...this.selectedRange,
        [key]: value,
      };

      this.changeSelectedRange(rangeData);
    },

    changeSelectedRange(value) {
      this.$emit('handleSelected', value);
    },
  },
};
</script>

<style>
  .invoices-export-filter-range-row-container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
  }

  .invoices-export-filter-range-dialog-combobox-menu .v-list-item {
    height: 40px;
    padding: 0 12px;
  }

  .invoices-export-filter-range-dialog-combobox-menu .v-select-list {
    padding: 0;
  }

  .invoices-export-filter-range-dialog-combobox-menu::-webkit-scrollbar {
    border-radius: 0 8px 8px 0;
    width: 6px;
  }

  .invoices-export-filter-range-dialog-combobox-menu::-webkit-scrollbar-thumb {
    background-color: #616161;
    border-radius: 12px;
  }

  .invoices-export-filter-range-dialog-combobox label,
  .invoices-export-filter-range-dialog-combobox {
    font-family: 'Roboto', serif;
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    font-style: normal;
    line-height: 115%;
  }

  .invoices-export-filter-range-dialog-combobox-selected-item {
    background-color: #616161;
  }

  .invoices-export-filter-range-box {
    flex: 2;
  }
</style>
