<template>
  <v-combobox
    dense
    multiple
    outlined
    hide-details
    label="Выберите столбцы"
    :items="invoicesColumns"
    :menu-props="{
      maxHeight: '250px',
      contentClass: 'invoices-export-table-columns-combobox-menu'
    }"
    :value="selectedColumns"
    class="invoices-export-table-columns-combobox"
  >
    <template #selection>
      {{ '' }}
    </template>

    <template
      v-if="selectedColumns.length"
      #prepend-inner
    >
      {{ selectedColumnText }}
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        class="px-3"
        v-bind="attrs"
        v-on="on"
        @click.stop="handleSelectColumn(item)"
      >
        <v-list-item-action class="mr-2 my-0">
          <v-checkbox :value="selectedColumns.includes(item)" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item === 'all' ? 'Все' : item }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'InvoicesExportColumnsSelect',
  props: {
    selectedColumns: {
      type: Array,
      default: () => [],
    },
    invoicesColumns: {
      type: Array,
      default: () => [],
    },
    handleSelectColumn: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    selectedColumnText() {
      return `Выбрано: ${this.selectedColumns.length === this.invoicesColumns.length ? 'Все' : this.selectedColumns.length}`;
    },
  },
};
</script>

<style>
.invoices-export-table-columns-combobox label,
.invoices-export-table-columns-combobox {
  font-family: 'Roboto', serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 115%;
}

.invoices-export-table-columns-combobox-menu::-webkit-scrollbar {
  border-radius: 0 8px 8px 0;
  width: 6px;
  background: transparent;
}

.invoices-export-table-columns-combobox-menu::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 12px;
}

.invoices-export-table-columns-combobox .v-select__slot {
  flex: 1;
}

.invoices-export-table-columns-combobox.v-input .v-input__control .v-input__slot .v-input__prepend-inner {
  position: fixed;
  margin-top: 14px;
  color: white;
}
</style>
