var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoices-export-filter-range-row-container"},[_c('v-combobox',{staticClass:"invoices-export-filter-range-dialog-combobox",attrs:{"label":_vm.label,"items":_vm.rangeItemsList,"value":_vm.selectedRange,"outlined":"","hide-details":"","item-text":"name","height":"40","dense":"","menu-props":{
      maxHeight: '300',
      rounded: 'lg',
      contentClass: 'invoices-export-filter-range-dialog-combobox-menu',
      closeOnClick: true,
      closeOnContentClick: true
    }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item',{class:{
          'invoices-export-filter-range-dialog-combobox-selected-item': _vm.isSelected(item)
        },on:{"click":function($event){return _vm.changeSelectedRange(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}])}),(_vm.isRange('specialDate'))?_c('invoices-date-calendar-field',{attrs:{"value":_vm.selectedRange.startDate,"label":"Выберите дату"},on:{"change":_vm.handleCurrentDate}}):_vm._e(),(_vm.isRange('range'))?_c('div',{staticClass:"d-flex align-center invoices-export-filter-range-box"},[_c('invoices-date-range-calendar-field',{attrs:{"value":_vm.selectedRange.startDate,"label":"Начала периода"},on:{"change":function($event){return _vm.handleRangeDate('startDate', $event)}}}),_c('div',{staticClass:"mx-2"},[_vm._v(" - ")]),_c('invoices-date-range-calendar-field',{attrs:{"value":_vm.selectedRange.endDate,"label":"Конец периода"},on:{"change":function($event){return _vm.handleRangeDate('endDate', $event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }