<template>
  <div>
    <topbar />
    <main-table />

    <!-- DIALOGS -->
    <invoices-export-dialog v-if="dialogs.exportInvoices" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InvoicesExportDialog from '@/components/invoices/InvoicesExportDialog.vue';
import MainTable from '../components/invoices/InvoicesMainTable.vue';
import Topbar from '../components/invoices/InvoicesTopbar.vue';

export default {
  name: 'Invoices',
  components: { InvoicesExportDialog, MainTable, Topbar },

  computed: {
    ...mapGetters({
      dialogs: 'invoices/dialogs',
    }),
  },
};
</script>

<style scoped>

</style>
