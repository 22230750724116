<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <div v-on="on">
        <v-combobox
          :value="formattedDate"
          :label="label"
          class="invoices-date-range-calendar-field-combobox"
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </template>
    <v-date-picker
      :value="value"
      no-title
      color="blue darken-2"
      class="invoices-date-range-calendar-field"
      @input="changeRange"
    />
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'InvoicesDateRangeCalendarField',
  props: ['value', 'label'],
  computed: {
    formattedDate() {
      return this.value ? moment(this.value).format('DD.MM.YYYY') : '';
    },
  },
  methods: {
    changeRange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style>
  .invoices-date-range-calendar-field-combobox,
  .invoices-date-range-calendar-field-combobox label,
  .invoices-date-range-calendar-field-date-picker {
    font-family: 'Roboto', serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 115%;
    flex: 1;
  }

  .invoices-date-range-calendar-field button {
    text-transform: capitalize;
  }
</style>
