export default [
  'all',
  'id',
  'teamId',
  'provider',
  'plan',
  'usersLimit',
  'browserProfilesLimit',
  'days',
  'sum',
  'fee',
  'currency',
  'promoId',
  'status',
  'created_at',
  'updated_at',
  'deleted_at',
  'payUrlCreatedDate',
  'payUrl',
  'exchange',
  'upgrade',
  'dolphinPayId',
  'coin',
  'recurringPaymentId',
  'recurringPlanId',
  'unlimintPaymentId',
  'barcode',
  'pdfUrl',
  'typableLine',
  'dueDate',
  'merchantId',
];
